import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-54e14875"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "blockie-placeholder mr-1 selectHover"
}
const _hoisted_2 = {
  key: 0,
  class: "overline primary--text font-weight-medium"
}
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mew_blockie = _resolveComponent("mew-blockie")!
  const _component_v_combobox = _resolveComponent("v-combobox")!

  return (_openBlock(), _createBlock(_component_v_combobox, {
    ref: "mewAddressSelect",
    modelValue: _ctx.locAddress,
    "onUpdate:modelValue": [
      _cache[0] || (_cache[0] = ($event: any) => ((_ctx.locAddress) = $event)),
      _ctx.debouncedChange
    ],
    class: "address-select pa-0 rounded-lg",
    color: "primary",
    label: _ctx.label,
    "item-value": "address",
    "item-text": "address",
    placeholder: _ctx.placeholder,
    disabled: _ctx.disabled,
    "error-messages": _ctx.errors,
    messages: _ctx.resolved ? _ctx.addressValue : '',
    rules: _ctx.rules,
    "no-data-text": _ctx.noDataText,
    "menu-props": { closeOnContentClick: true },
    variant: "outlined",
    "onUpdate:searchInput": _ctx.onChange
  }, {
    "prepend-inner": _withCtx(() => [
      (!_ctx.isValidAddress || !_ctx.blockieHash)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1))
        : _createCommentVNode("", true),
      (_ctx.isValidAddress)
        ? (_openBlock(), _createBlock(_component_mew_blockie, {
            key: 1,
            class: "mr-1",
            address: _ctx.blockieHash,
            width: "25px",
            height: "25px"
          }, null, 8, ["address"]))
        : _createCommentVNode("", true)
    ]),
    message: _withCtx(() => [
      _createElementVNode("div", null, [
        (_ctx.resolved)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.addressValue), 1))
          : _createCommentVNode("", true),
        (_ctx.errors.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.errors, (err) => {
                return (_openBlock(), _createElementBlock("span", {
                  key: err,
                  class: "error font-weight-medium"
                }, _toDisplayString(err), 1))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["modelValue", "label", "placeholder", "disabled", "error-messages", "messages", "rules", "no-data-text", "onUpdate:searchInput", "onUpdate:modelValue"]))
}